@import "../../../styles/base/abstracts";

.hbs-page-404__topper,
.hbs-page-404__content {
  @include grid-parent;
  @include site-max-width;
  width: 100%;
}

.hbs-page-404__topper-content,
.hbs-page-404__content-inner {
  @include grid-child;

  @include mq($bp-desktop-lg) {
    margin-left: $col-4;
    width: $col-8;
  }
}

.hbs-page-404__content-inner {
  @include block-spacing;
  @include block-spacing(bottom, $size: xl);
  @include rich-text;
}
